<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="`display: inline-block; ${computedContainerStyle}`" @click="onClick">
    <el-tooltip v-if="tooltip" effect="dark" :content="tooltip" placement="right">
      <el-button :type="_type" :size="size" :icon="buttonCssIcon" :plain="plain" :class="buttonCssClass" :style="computedStyle" :round="round" :disabled="isDisabled">
        <el-image v-if="buttonSrcIcon" class="_icon" fit="contain" :src="buttonSrcIcon"></el-image>
        <template v-if="text">
          {{text}}
        </template>
      </el-button>
    </el-tooltip>

    <el-button v-else :type="_type" :size="size" :icon="buttonCssIcon" :plain="plain" :class="buttonCssClass" :style="computedStyle" :round="round" :disabled="isDisabled">
      <el-image v-if="buttonSrcIcon" class="_icon" fit="contain" :src="buttonSrcIcon"></el-image>
      <template v-if="text">
        {{text}}
      </template>
    </el-button>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import Dashboard from '@/components/Dashboard'
import RegistryCard from '@/components/RegistryCard/index.vue'

import ActionExecutor from '@/core/infrastructure/service/ActionExecutor'

import { eventBus } from '@/eventBus'
import refreshComponentsMixin from '@/components/InterfaceEditor/components/refreshComponentsMixin'

export default {
  name: 'a-btn',

  components: {
    Dashboard,
    RegistryCard
  },

  inject: {
    getParentDashboard: {
      default: () => {}
    },
    getViewer: {
      default: () => {
        return {}
      }
    },
    getParentContext: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    updateTab: {
      default: () => {}
    },
    tabs: {
      default: () => {}
    },
    activeTab: {
      default: () => {}
    },
    closeTab: {
      default: () => {}
    },
    openedCards: {
      default: () => {}
    },
    cancelChanges: {
      default: () => {}
    },
    openRegistryCard: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    },
    openTabModalWindow: {
      default: () => {}
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    },
    getContainersStore: {
      default: () => () => {}
    },
    getInterfaceWrapper: {
      default: () => () => {}
    }
  },

  mixins: [mixin, VisibleMixin, refreshComponentsMixin],

  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    text: {
      type: String,
      description: 'text',
      default: 'Текст'
    },
    action: {
      type: Object,
      editor: 'ButtonAction',
      default: () => {
        return {
          type: 'execute_plugin',
          dashboard: {
            id: null,
            isFullscreen: false,
            window_width: 25,
            window_title: null,
            breadcrumbByButton: true,
            dashboardIdSource: 'dashboard'
          },
          command: {
            id: null,
            success_text: null,
            failure_text: null,
            is_card_close: null,
            close_type: null,
            is_save_card: null,
            is_reopen_card: null,
            is_save_card_before_command: null
          },
          card: {
            registryId: null,
            type: null,
            fieldId: null,
            constantRecordId: null,
            cardId: null,
            frameGuid: null,
            containerAlias: null,
            isWindow: false,
            windowWidth: 25,
            windowTitle: null,
            defaults: []
          },
          reports: {
            id: null,
            guid: null,
            name: null,
            formatType: null,
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false,
            reportName: null
          },
          saveCard: {
            isCloseAfterSave: false
          },
          url: {
            url: null,
            openType: null,
            urlAttr: null
          },
          refreshCard: {
            warnMessage: {
              onEdit: null,
              onRead: null
            },
            isSaveCardBeforeRefresh: false
          },
          etl_export: {
            task_id: null,
            filters: []
          },
          etl_import: {
            task_id: null,
            fields: null
          },
          open_tab_in_block: {
            block: null,
            tab: null,
            executeTabAction: false
          },
          refresh_components: {
            components: []
          }
        }
      }
    },
    pluginName: {
      type: String,
      description: 'plugin',
      editor: 'Plugin'
    },
    CSSContainter: {
      type: String,
      description: 'css_containera'
    },
    type: {
      type: String,
      description: 'type',
      editor: 'Select',
      options: {
        multiple: false,
        options: [
          { id: 'primary', name: 'Primary' },
          { id: 'success', name: 'Success' },
          { id: 'info', name: 'Info' },
          { id: 'warning', name: 'Warning' },
          { id: 'danger', name: 'Danger' },
          { id: 'text', name: 'Text' },
          { id: 'transparent', name: 'Transparent' } // Not vue.js option
        ],
        clearable: true
      }
    },
    plain: {
      type: Boolean,
      description: 'light_background',
      hidden: false
    },
    round: {
      type: Boolean,
      description: 'rounded_corner'
    },
    fillContainer: {
      type: Boolean,
      description: 'fill_container'
    },
    size: {
      type: String,
      description: 'size',
      editor: 'Size'
    },
    icon: {
      type: Object,
      description: 'icon',
      editor: 'Icon',
      default: () => {
        return { type: null, value: null }
      }
    },
    tooltip: {
      type: String,
      description: 'tooltip'
    },
    alwaysActive: {
      type: Boolean,
      description: 'always_active'
    }
  },

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    inCard () {
      return typeof this.getCard === 'function'
    },

    _type () {
      if (this.type !== 'transparent') { // Not vue.js option
        return this.type
      }

      return null
    },

    buttonCssClass () {
      if (this.type === 'transparent') {
        return 'button-transparent'
      }

      return null
    },

    computedStyle () {
      let css = this.CSS
      if (this.fillContainer) {
        css += ';width:100%;height:100%'
      }
      return css
    },

    computedContainerStyle () {
      let cssContainer = this.CSSContainter
      if (this.fillContainer) {
        cssContainer += ';width:100%;height:100%'
      }
      return cssContainer
    },

    buttonCssIcon () {
      if (this.icon && typeof this.icon === 'object' && this.icon.type === 'cssClass') {
        return this.icon.value
      }

      return null
    },

    buttonSrcIcon () {
      if (this.icon && typeof this.icon === 'object' && this.icon.type === 'src') {
        return this.icon.value
      }

      return null
    },

    isDisabled () {
      return this._isReadonly || this.isLoading
    }
  },

  watch: {
    type: {
      handler (value) {
        this.$emit('change-prop-property', { prop: 'plain', name: 'hidden', value: !value })
      },
      immediate: true
    },

    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },

  mounted () {
    if (this.action.icon && (!this.icon || !this.icon.type)) {
      // Конвертировать данные иконки в другой формат
      this.$emit('change-property', { name: 'icon', value: { type: 'cssClass', value: this.action.icon } })
      this.$set(this.action, 'icon', null)
    }
  },

  methods: {
    async onClick (event) {
      this.isLoading = true

      try {
        await ActionExecutor.execute(this, { readonly: this._isReadonly, pluginName: this.pluginName, action: this.action, event: event })
      } catch (error) {
        console.error('Ошибка действия кнопки', error)
      }

      this.isLoading = false
    }
  }
}
</script>

<style>
.button-transparent {
  border-color: transparent;
  background-color: transparent;
}
.button-transparent:hover {
  border-color: transparent;
  background-color: #ecf5ff;
}
.button-transparent:active {
  border-color: #3a8ee6;
  background-color: #e5edf6;
}
</style>
